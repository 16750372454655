<template>
  <div class="app-container">
    <eHeader :query="query" @toQuery="toQuery" />
    <!--表格增加了Cent账户 todo-->
    <el-table v-loading="loading" :data="data" stripe size="small" style="width: 100%;">
      <el-table-column prop="id" label="No." width="60"/>
      <el-table-column prop="bookName" label="类型">
         <template slot-scope="scope">
          <span>{{ parseGroup(scope.row.bookName) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="groupName" label="分组名称"/>
      <el-table-column prop="description" label="分组描述"/>
      <el-table-column prop="updateTime" label="更新时间">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.updateTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态">
        <template slot-scope="scope">
          <span>{{ getStatus(scope.row) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-popover
              v-if="checkPermission(['ADMIN'])"
              :ref="scope.row.id"
              placement="top"
              width="180">
            <p>确定{{getText(scope.row)}}该分组吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
              <el-button
                  :loading="btnLoading"
                  type="primary"
                  size="mini"
                  @click="disableOrEnableHandle(scope.row)"
              >确定</el-button>
            </div>
            <el-button
                size="mini"
                slot="reference"
                v-if="scope.row.status"
                :type="getType(scope.row)"
            >{{getText(scope.row)}}</el-button>
          </el-popover>

        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <public-pagination
            :total-page="total"
            :current-page="page"
            @pageChange="pageChange"
            @sizeChange="sizeChange"
    ></public-pagination>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission'
import initData from '@/mixins/initData'
import { del,toggleMt4GroupStatus } from '@/api/bussetup/tMt4Group'
import { parseTime } from '@/utils/index'
import eHeader from '@/components/bussetup/mt4group/header'
import { parseGroup } from '@/views/pt/ib/utils/index'
export default {
  name:'mtgroup',
  components: { eHeader },
  mixins: [initData],
  data() {
    return {
      btnLoading: false,
      sup_this: this,

    }
  },
  created() {
    this.$nextTick(() => {
      this.init()
    })
  },
  methods: {
    parseTime,
    parseGroup,
    checkPermission,
    beforeInit() {
      this.url = 'crm/tMt4Group'
      const sort = 'id,desc'
      this.params = { page: this.page, size: this.size, sort: sort }
      const query = this.query
      const type = query.type
      const value = query.value
      if (type && value) { this.params[type] = value }
      return true
    },
    getStatus(row){
      return row.status == 2? '禁用': '启用'
    },
    getText(row){
      return row.status == 2 ? '启用': '禁用'
    },
    getType(row){
      if(row.status == 2){
        return 'primary';
      }else {
        return 'danger';
      }
    },
    disableOrEnableHandle(row){
      let params = {
        id: row.id,
        status: row.status == 2 ? 1: 2,
      }
      this.btnLoading = true;
      toggleMt4GroupStatus(params).then((res) => {
        this.btnLoading = false;
        this.$refs[row.id].doClose();
        this.$message({
          message: '操作成功',
          type: 'success'
        });
        this.init();
      }).catch((error) => {
        this.btnLoading = false;
      })
    },
  }
}
</script>

<style scoped>

</style>
